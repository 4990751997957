import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import MemphisBeachFachada from "../../images/MemphisBeachFachada.jpg";
import picarrasAerea from "../../images/picarrasAerea.jpeg";
import Progress from "../../components/progress";

export default function MemphisResidence() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Memphis Residence"
      />
      <h2 className="my-16 italic text-center">
        O Empreendimento Memphis Residence tem tudo que a linha Bivver CLub
        oferece: espaço, conforto e sofisticação.
      </h2>
      <h3 className="text-center">
        Venha viver bem no Memphis Residence! Faça parte do Bivver Club
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-12 self-center">
        <img src={MemphisBeachFachada} />
        <p className="text-sm">
          Encontre neste empreendimento o conforto, a arquitetura moderna e a
          sofisticação que você procura!
          <br />
          <br />
          Apresentamos o Empreendimento Memphis Residence composto por quatro
          sobrados com ótimo padrão de acabamento, localizado em Balneário
          Piçarras a apenas 300 metros da praia. Sobrados diferenciados
          projetados para sua família com 100 metros quadrados (aproximadamente)
          distribuídos em 1 suíte, 2 dormitórios, banheiro social, sala de
          estar, sala de jantar, lavabo, cozinha e garagem, além de contar
          também em sua área externa com lavanderia, churrasqueira, chuveiro
          frio e vaga de estacionamento.
          <br />
          <br />
          Juntamos nossa dedicação pelo que fazemos às belezas naturais de
          Balneário Piçarras, proporcionando a sua família o lar perfeito no
          litoral norte catarinense.
          <br />
          <br />
          Praia com alto conceito em balneabilidade, certificada
          internacionalmente “Bandeira Azul”, portanto, uma das oitos melhores
          praias brasileiras para banho!
          <br />
          <br />
          O Empreendimento Memphis Residence está localizado em uma região
          tranquila e de fácil acesso, possui ampla área comercial e de serviços
          em seus arredores (escola, farmácia, supermercado, creche, posto de
          saúde, ponto de ônibus, padaria entre outros).
          <br />
          <br />
          Compre seu Bivver Club!{" "}
        </p>
      </div>
      <h2 className="text-center mb-8">Características</h2>
      <div className="bivverBlack mb-8 self-center">
        <div>
          <div>
            <b>Geminado - 106m²</b>
          </div>
          <div className="my-2">1 Suíte</div>
          <div className="my-2">1 Dormitório</div>
          <div className="my-2">Sala de jantar</div>
          <div className="my-2">Sala de estar</div>
          <div className="my-2">Cozinha</div>
          <div className="my-2">Área de serviço</div>
          <div className="my-2">2 Lavabos</div>
          <div className="my-2">1 Escritório</div>
          <div className="my-2">Banheiro social</div>
          <div className="my-2">2 Sacadas</div>
          <div className="my-2">Churrasqueira</div>
        </div>
      </div>

      <center>
        <h2>Acompanhamento da obra</h2>
      </center>
      <center>
        <h5>Data de inicio: 09/2019 | Data de término : xx/xxxx</h5>
      </center>
      <br />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Terraplanagem</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Fundação</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Estrutura</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Alvenaria</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Instalações</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Reboco Interno</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Reboco Externo</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Ceramica</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Esquadrias</h4>
        <Progress percentage="0" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:gap-10 lg:my-2 my-1 w-4/6 self-center">
        <h4 className="bivverBlack font-semibold">Pinturas</h4>
        <Progress percentage="0" />
      </div>
      <div>
        <h2 className="font-bold text-center my-10">Localização</h2>
        <iframe
          className="lg:w-1/2 w-full lg:mx-64 border-solid border-2 border-blue-500"
          title="SoftinLocationMap"
          height="360"
          frameBorder="0"
          src="https://maps.google.com/maps?q=Rua%203900%2C%20376&t=&z=15&ie=UTF8&iwloc=&output=embed"
          allowFullScreen=""
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-24 self-center">
        <div>
          <h2 className="mb-8">Por que morar em Piçarras?</h2>
          <p className="text-sm">
            Com uma localização geográfica privilegiada, ficando aproximadamento
            20 quilômetros do aeroporto de Navegantes e ligada por vias
            pavimentadas a BR-101, o que garante acesso rápido a outros polos de
            diversão e passeio, como o parque temático de Beto Carrero World, em
            Penha, a famosa Oktoberfest em Blumenau e ótimas compras em Brusque.
            <br />
            <br />O Balneário Piçarras abrange belas praias que possuem bandeira
            azul (certificado mundial de balneabilidade), tranquilidade e
            ótimcas opções de entretenimento em família. Com uma excelente
            infraestrutura, a cidade está preparada para recepcionar da melhor
            forma possível turistas de todos os cantos do mundo. Pousadas,
            bares, casas noturnas, mercados e restaurantes que oferecem o famoso
            rodízio de frutos do mar, são apenas uma pequena amostra do que
            Piçarras tem a oferecer.
          </p>
        </div>
        <img src={picarrasAerea} />
      </div>
    </Layout>
  );
}
